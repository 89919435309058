import { type Amount, addAmounts, divideByNumber, multiplyByRate, newAmount } from '@orus.eu/amount'
import type { PaymentRecurrence } from '../../payment-recurrence'
import type { InsuranceOutput } from '../insurance-output'
import type { QuoteV3 } from '../quote'
import { getInsuranceOutputAmount, monthsInAYear } from './orus-pricing-method'

export class QuoteCalculator {
  constructor(private readonly quote: QuoteV3) {}

  getFirstPaymentAmount({ paymentRecurrence }: { paymentRecurrence: PaymentRecurrence }): Amount {
    switch (this.quote.quoteModelVersion) {
      case 1:
        switch (paymentRecurrence) {
          case 'monthly': {
            return this.getMonthlyTotalAmountForPaymentRecurrence({
              paymentRecurrence,
              isAnnuityFirstMonth: true,
              isContractFirstMonth: true,
            })
          }
          case 'yearly': {
            return this.getYearlyTotalAmountForPaymentRecurrence({
              paymentRecurrence,
              isContractFirstMonth: true,
              isAnnuityFirstMonth: true,
            })
          }
        }
    }
  }

  getYearlyTotalAmountForPaymentRecurrence({
    paymentRecurrence,
    isContractFirstMonth,
    isAnnuityFirstMonth,
  }: {
    paymentRecurrence: PaymentRecurrence
    isContractFirstMonth: boolean
    isAnnuityFirstMonth: boolean
  }): Amount {
    switch (this.quote.quoteModelVersion) {
      case 1: {
        return this.getYearlyAmountForPaymentRecurrence({
          paymentRecurrence,
          isAnnuityFirstMonth,
          isContractFirstMonth,
          getInsuranceOutputAmount: getInsuranceOutputAmount,
        })
      }
    }
  }

  getMonthlyTotalAmountForPaymentRecurrence({
    paymentRecurrence,
    isAnnuityFirstMonth,
    isContractFirstMonth,
  }: {
    paymentRecurrence: PaymentRecurrence
    isAnnuityFirstMonth: boolean
    isContractFirstMonth: boolean
  }): Amount {
    switch (this.quote.quoteModelVersion) {
      case 1: {
        switch (paymentRecurrence) {
          case 'monthly': {
            return this.getMonthlyAmountForMonthlyRecurrence({
              isAnnuityFirstMonth,
              isContractFirstMonth,
              getInsuranceOutputAmount: getInsuranceOutputAmount,
            })
          }
          case 'yearly': {
            return divideByNumber(
              this.getYearlyTotalAmountForPaymentRecurrence({
                paymentRecurrence,
                isContractFirstMonth,
                isAnnuityFirstMonth,
              }),
              monthsInAYear,
            )
          }
        }
      }
    }
  }

  getMonthlyTotalExTaxAmountForPaymentRecurrence({
    paymentRecurrence,
    isAnnuityFirstMonth,
    isContractFirstMonth,
  }: {
    paymentRecurrence: PaymentRecurrence
    isAnnuityFirstMonth: boolean
    isContractFirstMonth: boolean
  }): Amount {
    switch (this.quote.quoteModelVersion) {
      case 1: {
        switch (paymentRecurrence) {
          case 'monthly': {
            return this.getMonthlyAmountForMonthlyRecurrence({
              isAnnuityFirstMonth,
              isContractFirstMonth,
              getInsuranceOutputAmount: this.getInsuranceOutputExTaxAmount,
            })
          }
          case 'yearly': {
            return divideByNumber(
              this.getYearlyTotalExTaxAmountForPaymentRecurrence({
                paymentRecurrence,
                isAnnuityFirstMonth,
                isContractFirstMonth,
              }),
              monthsInAYear,
            )
          }
        }
      }
    }
  }

  getYearlyTotalExTaxAmountForPaymentRecurrence({
    paymentRecurrence,
    isAnnuityFirstMonth,
    isContractFirstMonth,
  }: {
    paymentRecurrence: PaymentRecurrence
    isAnnuityFirstMonth: boolean
    isContractFirstMonth: boolean
  }): Amount {
    switch (this.quote.quoteModelVersion) {
      case 1: {
        return this.getYearlyAmountForPaymentRecurrence({
          paymentRecurrence,
          isAnnuityFirstMonth,
          isContractFirstMonth,
          getInsuranceOutputAmount: this.getInsuranceOutputExTaxAmount,
        })
      }
    }
  }

  getMonthlyTotalTaxesAmountForPaymentRecurrence({
    paymentRecurrence,
    isAnnuityFirstMonth,
    isContractFirstMonth,
  }: {
    paymentRecurrence: PaymentRecurrence
    isAnnuityFirstMonth: boolean
    isContractFirstMonth: boolean
  }): Amount {
    switch (this.quote.quoteModelVersion) {
      case 1: {
        switch (paymentRecurrence) {
          case 'monthly': {
            return this.getMonthlyAmountForMonthlyRecurrence({
              isAnnuityFirstMonth,
              isContractFirstMonth,
              getInsuranceOutputAmount: this.getInsuranceOutputTaxesAmount,
            })
          }
          case 'yearly': {
            return divideByNumber(
              this.getYearlyTotalTaxesAmountForPaymentRecurrence({
                paymentRecurrence,
                isAnnuityFirstMonth,
                isContractFirstMonth,
              }),
              monthsInAYear,
            )
          }
        }
      }
    }
  }

  getYearlyTotalTaxesAmountForPaymentRecurrence({
    paymentRecurrence,
    isAnnuityFirstMonth,
    isContractFirstMonth,
  }: {
    paymentRecurrence: PaymentRecurrence
    isAnnuityFirstMonth: boolean
    isContractFirstMonth: boolean
  }): Amount {
    switch (this.quote.quoteModelVersion) {
      case 1: {
        return this.getYearlyAmountForPaymentRecurrence({
          paymentRecurrence,
          isAnnuityFirstMonth,
          isContractFirstMonth,
          getInsuranceOutputAmount: this.getInsuranceOutputTaxesAmount,
        })
      }
    }
  }

  getMonthlyOrusInstallmentFeeAmountForPaymentRecurrence({
    paymentRecurrence,
    isAnnuityFirstMonth,
    isContractFirstMonth,
  }: {
    paymentRecurrence: PaymentRecurrence
    isAnnuityFirstMonth: boolean
    isContractFirstMonth: boolean
  }): Amount {
    switch (this.quote.quoteModelVersion) {
      case 1: {
        switch (paymentRecurrence) {
          case 'monthly': {
            return this.getMonthlyAmountForMonthlyRecurrence({
              isAnnuityFirstMonth,
              isContractFirstMonth,
              getInsuranceOutputAmount: this.getInsuranceOutputInstallmentFeeAmount,
            })
          }
          case 'yearly': {
            return divideByNumber(
              this.getYearlyOrusInstallmentFeeAmountForPaymentRecurrence({
                paymentRecurrence,
                isAnnuityFirstMonth,
                isContractFirstMonth,
              }),
              monthsInAYear,
            )
          }
        }
      }
    }
  }

  getYearlyOrusInstallmentFeeAmountForPaymentRecurrence({
    paymentRecurrence,
    isAnnuityFirstMonth,
    isContractFirstMonth,
  }: {
    paymentRecurrence: PaymentRecurrence
    isAnnuityFirstMonth: boolean
    isContractFirstMonth: boolean
  }): Amount {
    switch (this.quote.quoteModelVersion) {
      case 1: {
        return this.getYearlyAmountForPaymentRecurrence({
          paymentRecurrence,
          isAnnuityFirstMonth,
          isContractFirstMonth,
          getInsuranceOutputAmount: this.getInsuranceOutputInstallmentFeeAmount,
        })
      }
    }
  }

  getMonthlyPartnerManagementFeeAmountForPaymentRecurrence({
    paymentRecurrence,
    isAnnuityFirstMonth,
    isContractFirstMonth,
  }: {
    paymentRecurrence: PaymentRecurrence
    isAnnuityFirstMonth: boolean
    isContractFirstMonth: boolean
  }): Amount {
    switch (this.quote.quoteModelVersion) {
      case 1: {
        switch (paymentRecurrence) {
          case 'monthly': {
            return this.getMonthlyAmountForMonthlyRecurrence({
              isAnnuityFirstMonth,
              isContractFirstMonth,
              getInsuranceOutputAmount: this.getInsuranceOutputPartnerManagementFeeAmount,
            })
          }
          case 'yearly': {
            return divideByNumber(
              this.getYearlyPartnerManagementFeeAmountForPaymentRecurrence({
                paymentRecurrence,
                isAnnuityFirstMonth,
                isContractFirstMonth,
              }),
              monthsInAYear,
            )
          }
        }
      }
    }
  }

  getYearlyPartnerManagementFeeAmountForPaymentRecurrence({
    paymentRecurrence,
    isAnnuityFirstMonth,
    isContractFirstMonth,
  }: {
    paymentRecurrence: PaymentRecurrence
    isAnnuityFirstMonth: boolean
    isContractFirstMonth: boolean
  }): Amount {
    switch (this.quote.quoteModelVersion) {
      case 1: {
        return this.getYearlyAmountForPaymentRecurrence({
          paymentRecurrence,
          isAnnuityFirstMonth,
          isContractFirstMonth,
          getInsuranceOutputAmount: this.getInsuranceOutputPartnerManagementFeeAmount,
        })
      }
    }
  }

  getYearlyOrusManagementFeeAmountForPaymentRecurrence({
    paymentRecurrence,
    isAnnuityFirstMonth,
    isContractFirstMonth,
  }: {
    paymentRecurrence: PaymentRecurrence
    isAnnuityFirstMonth: boolean
    isContractFirstMonth: boolean
  }): Amount {
    switch (this.quote.quoteModelVersion) {
      case 1: {
        return this.getYearlyAmountForPaymentRecurrence({
          paymentRecurrence,
          isAnnuityFirstMonth,
          isContractFirstMonth,
          getInsuranceOutputAmount: this.getInsuranceOutputOrusManagementFeeAmount,
        })
      }
    }
  }

  getMonthlyOrusManagementFeeAmountForPaymentRecurrence({
    paymentRecurrence,
    isAnnuityFirstMonth,
    isContractFirstMonth,
  }: {
    paymentRecurrence: PaymentRecurrence
    isAnnuityFirstMonth: boolean
    isContractFirstMonth: boolean
  }): Amount {
    switch (this.quote.quoteModelVersion) {
      case 1: {
        switch (paymentRecurrence) {
          case 'monthly': {
            return this.getMonthlyAmountForMonthlyRecurrence({
              isAnnuityFirstMonth,
              isContractFirstMonth,
              getInsuranceOutputAmount: this.getInsuranceOutputOrusManagementFeeAmount,
            })
          }
          case 'yearly': {
            return divideByNumber(
              this.getYearlyPartnerManagementFeeAmountForPaymentRecurrence({
                paymentRecurrence,
                isAnnuityFirstMonth,
                isContractFirstMonth,
              }),
              monthsInAYear,
            )
          }
        }
      }
    }
  }

  //#region Payment recurrence methods
  /*
   * This method is used to get the monthly amount for a given insurance output.
   * It manages specific payment terms and divides by 12
   */
  getMonthlyzedAmount(
    insuranceOutput: InsuranceOutput,
    getInsuranceOutputAmount: (insuranceOutput: InsuranceOutput) => Amount,
  ): Amount {
    if (insuranceOutput.paymentTerms.type === 'oneTime' || insuranceOutput.paymentTerms.type === 'yearly') {
      return getInsuranceOutputAmount(insuranceOutput)
    }
    return divideByNumber(getInsuranceOutputAmount(insuranceOutput), monthsInAYear)
  }

  /*
   * This method is used to get the monthly amount (divided by 12) for monthly payment recurrence for a given insurance output.
   */
  getMonthlyAmountForMonthlyRecurrence({
    isAnnuityFirstMonth,
    isContractFirstMonth,
    getInsuranceOutputAmount,
  }: {
    getInsuranceOutputAmount: (insuranceOutput: InsuranceOutput) => Amount
    isAnnuityFirstMonth: boolean
    isContractFirstMonth: boolean
  }): Amount {
    const monthlyInsuranceOutputs = this.quote.monthly.billables
    const monthlyTotal = addAmounts(
      ...Object.values<InsuranceOutput>(monthlyInsuranceOutputs).map((insuranceOutput) =>
        (insuranceOutput.paymentTerms.type === 'oneTime' && !isContractFirstMonth) ||
        (insuranceOutput.paymentTerms.type === 'yearly' && !isAnnuityFirstMonth) ||
        (insuranceOutput.optionality.type === 'optional' && !insuranceOutput.optionality.isActive)
          ? newAmount(0)
          : this.getMonthlyzedAmount(insuranceOutput, getInsuranceOutputAmount),
      ),
    )
    return monthlyTotal
  }

  getYearlyAmountForPaymentRecurrence({
    paymentRecurrence,
    isAnnuityFirstMonth,
    isContractFirstMonth,
    getInsuranceOutputAmount,
  }: {
    paymentRecurrence: PaymentRecurrence
    isAnnuityFirstMonth: boolean
    isContractFirstMonth: boolean
    getInsuranceOutputAmount: (insuranceOutput: InsuranceOutput) => Amount
  }): Amount {
    const yearlyInsuranceOutputs = this.quote[paymentRecurrence].billables
    const yearlyTotal = addAmounts(
      ...Object.values<InsuranceOutput>(yearlyInsuranceOutputs).map((insuranceOutput) =>
        (insuranceOutput.paymentTerms.type === 'oneTime' && !isContractFirstMonth) ||
        (insuranceOutput.paymentTerms.type === 'yearly' && !isAnnuityFirstMonth) ||
        (insuranceOutput.optionality.type === 'optional' && !insuranceOutput.optionality.isActive)
          ? newAmount(0)
          : getInsuranceOutputAmount(insuranceOutput),
      ),
    )
    return yearlyTotal
  }

  //#endregion

  //#region Insurance Output Amounts Core methods
  getInsuranceOutputExTaxAmount(insuranceOutput: InsuranceOutput): Amount {
    return insuranceOutput.yearlyExTax
  }

  getInsuranceOutputTaxesAmount(insuranceOutput: InsuranceOutput): Amount {
    return multiplyByRate(insuranceOutput.yearlyExTax, insuranceOutput.taxRate)
  }

  getInsuranceOutputInstallmentFeeAmount(insuranceOutput: InsuranceOutput): Amount {
    return getInsuranceOutputAmount(insuranceOutput, { type: 'orusInstallmentFee' })
  }

  getInsuranceOutputOrusManagementFeeAmount(insuranceOutput: InsuranceOutput): Amount {
    return getInsuranceOutputAmount(insuranceOutput, { type: 'orusManagementFee' })
  }

  getInsuranceOutputPartnerManagementFeeAmount(insuranceOutput: InsuranceOutput): Amount {
    return getInsuranceOutputAmount(insuranceOutput, { type: 'partnerManagementFee' })
  }

  //#endregion
}
