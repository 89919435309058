import { addAmounts, areAmountsEqual, substractAmounts, zeroAmount, type Amount } from '@orus.eu/amount'
import { assertDefinedAndNotNull, checkDefinedAndNotNull } from '@orus.eu/error'
import type { Product } from '@orus.eu/product'
import {
  addMonthlyPremiums,
  addYearlyPremiums,
  allCyberGuarantees,
  allMrpSmallOfficeGuarantees,
  allTdmiGuarantees,
  QuoteCalculator,
  type PaymentRecurrence,
} from '@orus.eu/quote'
import { assertSuccess } from '@orus.eu/result'
import type { OrganizationType } from '@orus.eu/right-access-management'
import type { Language } from '@orus.eu/translations'
import type { PartialDimensionnedState } from '../dimension/abstract-dimension.js'
import type { quoteDimension } from '../dimension/aggregated-quote-dimension.js'
import type { paymentRecurrenceDimension } from '../dimension/common-dimensions.js'
import type { esRcphQuoteDimension, rcpaQuoteDimension } from '../dimension/index.js'
import { mrphQuoteV2Dimension } from '../dimension/mrph/index.js'
import { mrpwQuoteDimension } from '../dimension/mrpw/index.js'
import { mutaQuoteDimension } from '../dimension/muta/index.js'
import {
  localizeAmount,
  localizeRecurrence,
  type ProductInformationId,
} from '../dimension/quote-offer-information-dimension.js'
import { getRcdaInstallmentFeePerMonth, rcdaQuoteDimension } from '../dimension/rcda/index.js'
import { rcphQuoteV2Dimension } from '../dimension/rcph/index.js'
import { productSelectionDimensions } from '../product.js'

export type CoverageInvoicingItemInputState = PartialDimensionnedState<
  [
    typeof paymentRecurrenceDimension,
    ...typeof productSelectionDimensions,
    typeof mrphQuoteV2Dimension,
    typeof mrpwQuoteDimension,
    typeof mutaQuoteDimension,
    typeof rcphQuoteV2Dimension,
    typeof rcdaQuoteDimension,
    typeof quoteDimension,
    typeof esRcphQuoteDimension,
    typeof rcpaQuoteDimension,
  ]
>

export type InvoicingAmounts = {
  premiumWithoutTaxes: Amount
  /**
   * The total amount of insurance taxes
   */
  insuranceTaxes: Amount
  /**
   * The French "contribution attentat", included in the insuranceTaxes field
   */
  terrorismTax: Amount
  /**
   * installment fees included in totalPremium
   */
  installmentFee: Amount
  assistanceVAT: Amount
  assistancePremiumWithoutTaxes: Amount
  /**
   * The total due amount for this item, should equal premiumWithoutTaxes + insuranceTaxes + assistanceVAT
   */
  totalPremium: Amount
  orusManagementFee: Amount
  partnerManagementFee: Amount
  orusFee: Amount
}

export type ItemAmountsFactory = (params: {
  input: CoverageInvoicingItemInputState
  isInFirstYear: boolean
  isFirstOfYear: boolean
  isFirstEver: boolean
  organizationType: OrganizationType
}) => {
  /**
   * The amount without Orus Discount, we need this data for Hiscox reporting.
   * We don't have the information in the Wakam quote, but luckily we don't need it right now. If at some point we need it, we'll need to change the Wakam quote.
   */
  withoutOrusDiscount?: InvoicingAmounts
  withoutPartnerFees: InvoicingAmounts | undefined
  withoutOrusAndPartnerFees: InvoicingAmounts | undefined
  final: InvoicingAmounts
}

const freeProductAmounts = {
  withoutOrusDiscount: {
    premiumWithoutTaxes: zeroAmount,
    insuranceTaxes: zeroAmount,
    terrorismTax: zeroAmount,
    assistanceVAT: zeroAmount,
    assistancePremiumWithoutTaxes: zeroAmount,
    totalPremium: zeroAmount,
    orusFee: zeroAmount,
    installmentFee: zeroAmount,
    orusManagementFee: zeroAmount,
    partnerManagementFee: zeroAmount,
  },
  withoutPartnerFees: {
    premiumWithoutTaxes: zeroAmount,
    insuranceTaxes: zeroAmount,
    terrorismTax: zeroAmount,
    assistanceVAT: zeroAmount,
    assistancePremiumWithoutTaxes: zeroAmount,
    totalPremium: zeroAmount,
    orusFee: zeroAmount,
    installmentFee: zeroAmount,
    orusManagementFee: zeroAmount,
    partnerManagementFee: zeroAmount,
  },
  withoutOrusAndPartnerFees: {
    premiumWithoutTaxes: zeroAmount,
    insuranceTaxes: zeroAmount,
    terrorismTax: zeroAmount,
    assistanceVAT: zeroAmount,
    assistancePremiumWithoutTaxes: zeroAmount,
    totalPremium: zeroAmount,
    orusFee: zeroAmount,
    installmentFee: zeroAmount,
    orusManagementFee: zeroAmount,
    partnerManagementFee: zeroAmount,
  },
  final: {
    premiumWithoutTaxes: zeroAmount,
    insuranceTaxes: zeroAmount,
    terrorismTax: zeroAmount,
    assistanceVAT: zeroAmount,
    assistancePremiumWithoutTaxes: zeroAmount,
    totalPremium: zeroAmount,
    orusFee: zeroAmount,
    installmentFee: zeroAmount,
    orusManagementFee: zeroAmount,
    partnerManagementFee: zeroAmount,
  },
}

const esRcphAmountFactory: Record<PaymentRecurrence, ItemAmountsFactory> = {
  monthly: ({ input, isFirstOfYear }) => {
    const esRcphQuote = checkDefinedAndNotNull(input.esRcphQuote)
    const { withoutOrusDiscount, withoutPartnerFees, withoutOrusAndPartnerFees, final } = esRcphQuote.monthly

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: withoutOrusDiscount.total.exTax,
        insuranceTaxes: withoutOrusDiscount.total.tax,
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: isFirstOfYear
          ? withoutOrusDiscount.total.firstMonthTotal
          : withoutOrusDiscount.total.subsequentMonthsTotal,
        orusFee: withoutOrusDiscount.total.commission,
        installmentFee: zeroAmount,
        orusManagementFee: zeroAmount,
        partnerManagementFee: zeroAmount,
      },
      withoutPartnerFees: withoutPartnerFees
        ? {
            premiumWithoutTaxes: withoutPartnerFees.total.exTax,
            insuranceTaxes: withoutPartnerFees.total.tax,
            terrorismTax: zeroAmount,
            assistanceVAT: zeroAmount,
            assistancePremiumWithoutTaxes: zeroAmount,
            totalPremium: isFirstOfYear
              ? withoutPartnerFees.total.firstMonthTotal
              : withoutPartnerFees.total.subsequentMonthsTotal,
            orusFee: withoutPartnerFees.total.commission,
            installmentFee: zeroAmount,
            orusManagementFee: zeroAmount,
            partnerManagementFee: zeroAmount,
          }
        : undefined,
      withoutOrusAndPartnerFees: withoutOrusAndPartnerFees
        ? {
            premiumWithoutTaxes: withoutOrusAndPartnerFees.total.exTax,
            insuranceTaxes: withoutOrusAndPartnerFees.total.tax,
            terrorismTax: zeroAmount,
            assistanceVAT: zeroAmount,
            assistancePremiumWithoutTaxes: zeroAmount,
            totalPremium: isFirstOfYear
              ? withoutOrusAndPartnerFees.total.firstMonthTotal
              : withoutOrusAndPartnerFees.total.subsequentMonthsTotal,

            orusFee: withoutOrusAndPartnerFees.total.commission,
            installmentFee: zeroAmount,
            orusManagementFee: zeroAmount,
            partnerManagementFee: zeroAmount,
          }
        : undefined,
      final: {
        premiumWithoutTaxes: final.total.exTax,
        insuranceTaxes: final.total.tax,
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: isFirstOfYear ? final.total.firstMonthTotal : final.total.subsequentMonthsTotal,
        orusFee: final.total.commission,
        installmentFee: final.orusFees?.installmentFee?.premium.total ?? zeroAmount,
        partnerManagementFee: final.partnerFees?.managementFee?.premium.total ?? zeroAmount,
        orusManagementFee: final.orusFees?.managementFee?.premium.total ?? zeroAmount,
      },
    }
  },
  yearly: ({ input }) => {
    const esRcphQuote = checkDefinedAndNotNull(input.esRcphQuote)
    const { withoutOrusDiscount, withoutPartnerFees, withoutOrusAndPartnerFees, final } = esRcphQuote.yearly

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: withoutOrusDiscount.total.exTax,
        insuranceTaxes: withoutOrusDiscount.total.tax,
        terrorismTax: zeroAmount,
        installmentFee: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: withoutOrusDiscount.total.total,
        orusFee: withoutOrusDiscount.total.commission,
        orusManagementFee: zeroAmount,
        partnerManagementFee: zeroAmount,
      },
      withoutPartnerFees: withoutPartnerFees
        ? {
            premiumWithoutTaxes: withoutPartnerFees.total.exTax,
            insuranceTaxes: withoutPartnerFees.total.tax,
            terrorismTax: zeroAmount,
            installmentFee: zeroAmount,
            assistanceVAT: zeroAmount,
            assistancePremiumWithoutTaxes: zeroAmount,
            totalPremium: withoutPartnerFees.total.total,
            orusFee: withoutPartnerFees.total.commission,
            orusManagementFee: zeroAmount,
            partnerManagementFee: zeroAmount,
          }
        : undefined,
      withoutOrusAndPartnerFees: withoutOrusAndPartnerFees
        ? {
            premiumWithoutTaxes: withoutOrusAndPartnerFees.total.exTax,
            insuranceTaxes: withoutOrusAndPartnerFees.total.tax,
            terrorismTax: zeroAmount,
            installmentFee: zeroAmount,
            assistanceVAT: zeroAmount,
            assistancePremiumWithoutTaxes: zeroAmount,
            totalPremium: withoutOrusAndPartnerFees.total.total,
            orusFee: withoutOrusAndPartnerFees.total.commission,
            orusManagementFee: zeroAmount,
            partnerManagementFee: zeroAmount,
          }
        : undefined,
      final: {
        premiumWithoutTaxes: final.total.exTax,
        insuranceTaxes: final.total.tax,
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: final.total.total,
        orusFee: final.total.commission,
        installmentFee: final.orusFees?.installmentFee?.premium.total ?? zeroAmount,
        partnerManagementFee: final.partnerFees?.managementFee?.premium.total ?? zeroAmount,
        orusManagementFee: final.orusFees?.managementFee?.premium.total ?? zeroAmount,
      },
    }
  },
}

export const productInformationIdAmountFactories: Record<
  ProductInformationId,
  Record<PaymentRecurrence, ItemAmountsFactory>
> = {
  'rc-pro': {
    monthly: ({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }) =>
      rcphWithoutCyberAndTdmiAmountFactories.monthly({
        input,
        isInFirstYear,
        isFirstOfYear,
        isFirstEver,
        organizationType,
      }),
    yearly: ({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }) =>
      rcphWithoutCyberAndTdmiAmountFactories.yearly({
        input,
        isInFirstYear,
        isFirstOfYear,
        isFirstEver,
        organizationType,
      }),
  },

  'rcph-cyber': {
    monthly: ({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }) =>
      rcphCyberAmountFactories.monthly({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }),
    yearly: ({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }) =>
      rcphCyberAmountFactories.yearly({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }),
  },
  'rcph-tdmi': {
    monthly: ({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }) =>
      rcphTdmiAmountFactories.monthly({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }),
    yearly: ({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }) =>
      rcphTdmiAmountFactories.yearly({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }),
  },
  'rcph-pj': {
    monthly: () => freeProductAmounts,
    yearly: () => freeProductAmounts,
  },
  mrph: {
    monthly: ({
      input,
      isFirstOfYear,
    }): {
      withoutOrusDiscount: InvoicingAmounts
      withoutPartnerFees: InvoicingAmounts | undefined
      withoutOrusAndPartnerFees: InvoicingAmounts | undefined
      final: InvoicingAmounts
    } => {
      const mrphQuoteV2 = checkDefinedAndNotNull(input.mrphQuoteV2)
      const { withoutOrusDiscount, withoutPartnerFees, withoutOrusAndPartnerFees, final } = mrphQuoteV2.monthly

      return {
        withoutOrusDiscount: {
          premiumWithoutTaxes: withoutOrusDiscount.total.exTax,
          insuranceTaxes: withoutOrusDiscount.total.tax,
          terrorismTax: isFirstOfYear ? (mrphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
          assistanceVAT: withoutOrusDiscount.options.assistance?.premium.tax ?? zeroAmount,
          assistancePremiumWithoutTaxes: withoutOrusDiscount.options.assistance?.premium.exTax ?? zeroAmount,
          totalPremium: isFirstOfYear
            ? withoutOrusDiscount.total.firstMonthTotal
            : withoutOrusDiscount.total.subsequentMonthsTotal,
          orusFee: withoutOrusDiscount.total.commission,
          installmentFee: zeroAmount,

          partnerManagementFee: zeroAmount,
          orusManagementFee: zeroAmount,
        },
        withoutPartnerFees: withoutPartnerFees
          ? {
              premiumWithoutTaxes: withoutPartnerFees.total.exTax,
              insuranceTaxes: withoutPartnerFees.total.tax,
              terrorismTax: isFirstOfYear ? (mrphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
              assistanceVAT: withoutPartnerFees.options.assistance?.premium.tax ?? zeroAmount,
              assistancePremiumWithoutTaxes: withoutPartnerFees.options.assistance?.premium.exTax ?? zeroAmount,
              totalPremium: isFirstOfYear
                ? withoutPartnerFees.total.firstMonthTotal
                : withoutPartnerFees.total.subsequentMonthsTotal,
              orusFee: withoutPartnerFees.total.commission,
              installmentFee: zeroAmount,

              partnerManagementFee: zeroAmount,
              orusManagementFee: zeroAmount,
            }
          : undefined,
        withoutOrusAndPartnerFees: withoutOrusAndPartnerFees
          ? {
              premiumWithoutTaxes: withoutOrusAndPartnerFees.total.exTax,
              insuranceTaxes: withoutOrusAndPartnerFees.total.tax,
              terrorismTax: isFirstOfYear ? (mrphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
              assistanceVAT: withoutOrusAndPartnerFees.options.assistance?.premium.tax ?? zeroAmount,
              assistancePremiumWithoutTaxes: withoutOrusAndPartnerFees.options.assistance?.premium.exTax ?? zeroAmount,
              totalPremium: isFirstOfYear
                ? withoutOrusAndPartnerFees.total.firstMonthTotal
                : withoutOrusAndPartnerFees.total.subsequentMonthsTotal,
              orusFee: withoutOrusAndPartnerFees.total.commission,
              installmentFee: zeroAmount,

              partnerManagementFee: zeroAmount,
              orusManagementFee: zeroAmount,
            }
          : undefined,
        final: {
          premiumWithoutTaxes: final.total.exTax,
          insuranceTaxes: final.total.tax,
          terrorismTax: isFirstOfYear ? (mrphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
          assistanceVAT: final.options.assistance?.premium.tax ?? zeroAmount,
          assistancePremiumWithoutTaxes: final.options.assistance?.premium.exTax ?? zeroAmount,
          totalPremium: isFirstOfYear ? final.total.firstMonthTotal : final.total.subsequentMonthsTotal,
          orusFee: final.total.commission,
          installmentFee: final.orusFees?.installmentFee?.premium.total ?? zeroAmount,
          partnerManagementFee: final.partnerFees?.managementFee?.premium.total ?? zeroAmount,
          orusManagementFee: final.orusFees?.managementFee?.premium.total ?? zeroAmount,
        },
      }
    },
    yearly: ({
      input,
    }): {
      withoutOrusDiscount: InvoicingAmounts
      withoutPartnerFees: InvoicingAmounts | undefined
      withoutOrusAndPartnerFees: InvoicingAmounts | undefined
      final: InvoicingAmounts
    } => {
      const mrphQuoteV2 = checkDefinedAndNotNull(input.mrphQuoteV2)
      const { withoutOrusDiscount, withoutPartnerFees, withoutOrusAndPartnerFees, final } = mrphQuoteV2.yearly

      return {
        withoutOrusDiscount: {
          premiumWithoutTaxes: withoutOrusDiscount.total.exTax,
          insuranceTaxes: withoutOrusDiscount.total.tax,
          terrorismTax: mrphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
          assistanceVAT: withoutOrusDiscount.options.assistance?.premium.tax ?? zeroAmount,
          assistancePremiumWithoutTaxes: withoutOrusDiscount.options.assistance?.premium.exTax ?? zeroAmount,
          totalPremium: withoutOrusDiscount.total.total,
          orusFee: withoutOrusDiscount.total.commission,
          installmentFee: zeroAmount,

          partnerManagementFee: zeroAmount,
          orusManagementFee: zeroAmount,
        },
        withoutPartnerFees: withoutPartnerFees
          ? {
              premiumWithoutTaxes: withoutPartnerFees.total.exTax,
              insuranceTaxes: withoutPartnerFees.total.tax,
              terrorismTax: mrphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
              assistanceVAT: withoutPartnerFees.options.assistance?.premium.tax ?? zeroAmount,
              assistancePremiumWithoutTaxes: withoutPartnerFees.options.assistance?.premium.exTax ?? zeroAmount,
              totalPremium: withoutPartnerFees.total.total,
              orusFee: withoutPartnerFees.total.commission,
              installmentFee: zeroAmount,

              partnerManagementFee: zeroAmount,
              orusManagementFee: zeroAmount,
            }
          : undefined,
        withoutOrusAndPartnerFees: withoutOrusAndPartnerFees
          ? {
              premiumWithoutTaxes: withoutOrusAndPartnerFees.total.exTax,
              insuranceTaxes: withoutOrusAndPartnerFees.total.tax,
              terrorismTax: mrphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
              assistanceVAT: withoutOrusAndPartnerFees.options.assistance?.premium.tax ?? zeroAmount,
              assistancePremiumWithoutTaxes: withoutOrusAndPartnerFees.options.assistance?.premium.exTax ?? zeroAmount,
              totalPremium: withoutOrusAndPartnerFees.total.total,
              orusFee: withoutOrusAndPartnerFees.total.commission,
              installmentFee: zeroAmount,

              partnerManagementFee: zeroAmount,
              orusManagementFee: zeroAmount,
            }
          : undefined,
        final: {
          premiumWithoutTaxes: final.total.exTax,
          insuranceTaxes: final.total.tax,
          terrorismTax: mrphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
          assistanceVAT: final.options.assistance?.premium.tax ?? zeroAmount,
          assistancePremiumWithoutTaxes: final.options.assistance?.premium.exTax ?? zeroAmount,
          totalPremium: final.total.total,
          orusFee: final.total.commission,
          installmentFee: final.orusFees?.installmentFee?.premium.total ?? zeroAmount,
          partnerManagementFee: final.partnerFees?.managementFee?.premium.total ?? zeroAmount,
          orusManagementFee: final.orusFees?.managementFee?.premium.total ?? zeroAmount,
        },
      }
    },
  },
  mrpw: {
    monthly: ({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }) =>
      mrpwAmountFactories.monthly({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }),
    yearly: ({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }) =>
      mrpwAmountFactories.yearly({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }),
  },
  muta: {
    monthly: ({ input, isFirstOfYear }) => {
      const mutaQuote = checkDefinedAndNotNull(input.mutaQuote)
      const { withoutOrusDiscount, withoutPartnerFees, withoutOrusAndPartnerFees, final } = mutaQuote.monthly

      return {
        withoutOrusDiscount: {
          premiumWithoutTaxes: withoutOrusDiscount.total.exTax,
          insuranceTaxes: withoutOrusDiscount.total.tax,
          terrorismTax: zeroAmount,
          assistanceVAT: withoutOrusDiscount.guarantees.assistance?.premium.tax ?? zeroAmount,
          assistancePremiumWithoutTaxes: withoutOrusDiscount.guarantees.assistance?.premium.exTax ?? zeroAmount,
          totalPremium: isFirstOfYear
            ? withoutOrusDiscount.total.firstMonthTotal
            : withoutOrusDiscount.total.subsequentMonthsTotal,
          orusFee: withoutOrusDiscount.total.commission,
          installmentFee: zeroAmount,

          partnerManagementFee: zeroAmount,
          orusManagementFee: zeroAmount,
        },
        withoutPartnerFees: withoutPartnerFees
          ? {
              premiumWithoutTaxes: withoutPartnerFees.total.exTax,
              insuranceTaxes: withoutPartnerFees.total.tax,
              terrorismTax: zeroAmount,
              assistanceVAT: withoutPartnerFees.guarantees.assistance?.premium.tax ?? zeroAmount,
              assistancePremiumWithoutTaxes: withoutPartnerFees.guarantees.assistance?.premium.exTax ?? zeroAmount,
              totalPremium: isFirstOfYear
                ? withoutPartnerFees.total.firstMonthTotal
                : withoutPartnerFees.total.subsequentMonthsTotal,
              orusFee: withoutPartnerFees.total.commission,
              installmentFee: zeroAmount,

              partnerManagementFee: zeroAmount,
              orusManagementFee: zeroAmount,
            }
          : undefined,
        withoutOrusAndPartnerFees: withoutOrusAndPartnerFees
          ? {
              premiumWithoutTaxes: withoutOrusAndPartnerFees.total.exTax,
              insuranceTaxes: withoutOrusAndPartnerFees.total.tax,
              terrorismTax: zeroAmount,
              assistanceVAT: withoutOrusAndPartnerFees.guarantees.assistance?.premium.tax ?? zeroAmount,
              assistancePremiumWithoutTaxes:
                withoutOrusAndPartnerFees.guarantees.assistance?.premium.exTax ?? zeroAmount,
              totalPremium: isFirstOfYear
                ? withoutOrusAndPartnerFees.total.firstMonthTotal
                : withoutOrusAndPartnerFees.total.subsequentMonthsTotal,
              orusFee: withoutOrusAndPartnerFees.total.commission,
              installmentFee: zeroAmount,

              partnerManagementFee: zeroAmount,
              orusManagementFee: zeroAmount,
            }
          : undefined,
        final: {
          premiumWithoutTaxes: final.total.exTax,
          insuranceTaxes: final.total.tax,
          terrorismTax: zeroAmount,
          assistanceVAT: final.guarantees.assistance?.premium.tax ?? zeroAmount,
          assistancePremiumWithoutTaxes: final.guarantees.assistance?.premium.exTax ?? zeroAmount,
          totalPremium: isFirstOfYear ? final.total.firstMonthTotal : final.total.subsequentMonthsTotal,
          orusFee: final.total.commission,
          installmentFee: final.orusFees?.installmentFee?.premium.total ?? zeroAmount,
          partnerManagementFee: final.partnerFees?.managementFee?.premium.total ?? zeroAmount,
          orusManagementFee: final.orusFees?.managementFee?.premium.total ?? zeroAmount,
        },
      }
    },
    yearly: ({ input }) => {
      const mutaQuote = checkDefinedAndNotNull(input.mutaQuote)
      const { withoutOrusDiscount, withoutPartnerFees, withoutOrusAndPartnerFees, final } = mutaQuote.yearly
      return {
        withoutOrusDiscount: {
          premiumWithoutTaxes: withoutOrusDiscount.total.exTax,
          insuranceTaxes: withoutOrusDiscount.total.tax,
          terrorismTax: zeroAmount,
          assistanceVAT: withoutOrusDiscount.guarantees.assistance?.premium.tax ?? zeroAmount,
          assistancePremiumWithoutTaxes: withoutOrusDiscount.guarantees.assistance?.premium.exTax ?? zeroAmount,
          totalPremium: withoutOrusDiscount.total.total,
          orusFee: withoutOrusDiscount.total.commission,
          installmentFee: zeroAmount,

          partnerManagementFee: zeroAmount,
          orusManagementFee: zeroAmount,
        },
        withoutPartnerFees: withoutPartnerFees
          ? {
              premiumWithoutTaxes: withoutPartnerFees.total.exTax,
              insuranceTaxes: withoutPartnerFees.total.tax,
              terrorismTax: zeroAmount,
              assistanceVAT: withoutPartnerFees.guarantees.assistance?.premium.tax ?? zeroAmount,
              assistancePremiumWithoutTaxes: withoutPartnerFees.guarantees.assistance?.premium.exTax ?? zeroAmount,
              totalPremium: withoutPartnerFees.total.total,
              orusFee: withoutPartnerFees.total.commission,
              installmentFee: zeroAmount,

              partnerManagementFee: zeroAmount,
              orusManagementFee: zeroAmount,
            }
          : undefined,
        withoutOrusAndPartnerFees: withoutOrusAndPartnerFees
          ? {
              premiumWithoutTaxes: withoutOrusAndPartnerFees.total.exTax,
              insuranceTaxes: withoutOrusAndPartnerFees.total.tax,
              terrorismTax: zeroAmount,
              assistanceVAT: withoutOrusAndPartnerFees.guarantees.assistance?.premium.tax ?? zeroAmount,
              assistancePremiumWithoutTaxes:
                withoutOrusAndPartnerFees.guarantees.assistance?.premium.exTax ?? zeroAmount,
              totalPremium: withoutOrusAndPartnerFees.total.total,
              orusFee: withoutOrusAndPartnerFees.total.commission,
              installmentFee: zeroAmount,

              partnerManagementFee: zeroAmount,
              orusManagementFee: zeroAmount,
            }
          : undefined,
        final: {
          premiumWithoutTaxes: final.total.exTax,
          insuranceTaxes: final.total.tax,
          terrorismTax: zeroAmount,
          assistanceVAT: final.guarantees.assistance?.premium.tax ?? zeroAmount,
          assistancePremiumWithoutTaxes: final.guarantees.assistance?.premium.exTax ?? zeroAmount,
          totalPremium: final.total.total,
          orusFee: final.total.commission,
          installmentFee: final.orusFees?.installmentFee?.premium.total ?? zeroAmount,
          partnerManagementFee: final.partnerFees?.managementFee?.premium.total ?? zeroAmount,
          orusManagementFee: final.orusFees?.managementFee?.premium.total ?? zeroAmount,
        },
      }
    },
  },
  rcda: {
    monthly: ({ input, isFirstOfYear, isFirstEver }) => {
      const rcdaQuote = checkDefinedAndNotNull(input.rcdaQuote)
      const { withoutOrusDiscount, withoutPartnerFees, withoutOrusAndPartnerFees, final } = rcdaQuote.monthly

      const oneTime =
        isFirstEver && rcdaQuote.oneTime?.final.options.historyTakeover?.active === true ? rcdaQuote.oneTime : undefined

      return {
        withoutOrusDiscount: {
          premiumWithoutTaxes: addAmounts(
            withoutOrusDiscount.total.exTax,
            oneTime?.withoutOrusDiscount.options.historyTakeover?.premium.exTax ?? zeroAmount,
          ),
          insuranceTaxes: addAmounts(
            withoutOrusDiscount.total.tax,
            oneTime?.withoutOrusDiscount.options.historyTakeover?.premium.tax ?? zeroAmount,
          ),
          terrorismTax: zeroAmount,
          assistanceVAT: zeroAmount,
          assistancePremiumWithoutTaxes: zeroAmount,
          totalPremium: addAmounts(
            isFirstOfYear ? withoutOrusDiscount.total.firstMonthTotal : withoutOrusDiscount.total.subsequentMonthsTotal,
            oneTime?.withoutOrusDiscount.options.historyTakeover?.premium.total ?? zeroAmount,
          ),
          orusFee: addAmounts(
            withoutOrusDiscount.total.commission,
            oneTime?.withoutOrusDiscount.options.historyTakeover?.premium.commission ?? zeroAmount,
          ),
          installmentFee: getRcdaInstallmentFeePerMonth(rcdaQuote) ?? zeroAmount,

          partnerManagementFee: zeroAmount,
          orusManagementFee: zeroAmount,
        },
        withoutPartnerFees: withoutPartnerFees
          ? {
              premiumWithoutTaxes: addAmounts(
                withoutPartnerFees.total.exTax,
                oneTime?.withoutPartnerFees?.options.historyTakeover?.premium.exTax ?? zeroAmount,
              ),
              insuranceTaxes: addAmounts(
                withoutPartnerFees.total.tax,
                oneTime?.withoutPartnerFees?.options.historyTakeover?.premium.tax ?? zeroAmount,
              ),
              terrorismTax: zeroAmount,
              assistanceVAT: zeroAmount,
              assistancePremiumWithoutTaxes: zeroAmount,
              totalPremium: addAmounts(
                isFirstOfYear
                  ? withoutPartnerFees.total.firstMonthTotal
                  : withoutPartnerFees.total.subsequentMonthsTotal,
                oneTime?.withoutPartnerFees?.options.historyTakeover?.premium.total ?? zeroAmount,
              ),
              orusFee: addAmounts(
                withoutPartnerFees.total.commission,
                oneTime?.withoutPartnerFees?.options.historyTakeover?.premium.commission ?? zeroAmount,
              ),
              installmentFee: getRcdaInstallmentFeePerMonth(rcdaQuote) ?? zeroAmount,

              partnerManagementFee: zeroAmount,
              orusManagementFee: zeroAmount,
            }
          : undefined,
        withoutOrusAndPartnerFees: withoutOrusAndPartnerFees
          ? {
              premiumWithoutTaxes: addAmounts(
                withoutOrusAndPartnerFees.total.exTax,
                oneTime?.withoutOrusAndPartnerFees?.options.historyTakeover?.premium.exTax ?? zeroAmount,
              ),
              insuranceTaxes: addAmounts(
                withoutOrusAndPartnerFees.total.tax,
                oneTime?.withoutOrusAndPartnerFees?.options.historyTakeover?.premium.tax ?? zeroAmount,
              ),
              terrorismTax: zeroAmount,
              assistanceVAT: zeroAmount,
              assistancePremiumWithoutTaxes: zeroAmount,
              totalPremium: addAmounts(
                isFirstOfYear
                  ? withoutOrusAndPartnerFees.total.firstMonthTotal
                  : withoutOrusAndPartnerFees.total.subsequentMonthsTotal,
                oneTime?.withoutOrusAndPartnerFees?.options.historyTakeover?.premium.total ?? zeroAmount,
              ),
              orusFee: addAmounts(
                withoutOrusAndPartnerFees.total.commission,
                oneTime?.withoutOrusAndPartnerFees?.options.historyTakeover?.premium.commission ?? zeroAmount,
              ),
              installmentFee: getRcdaInstallmentFeePerMonth(rcdaQuote) ?? zeroAmount,

              partnerManagementFee: zeroAmount,
              orusManagementFee: zeroAmount,
            }
          : undefined,
        final: {
          premiumWithoutTaxes: addAmounts(
            final.total.exTax,
            oneTime?.final.options.historyTakeover?.premium.exTax ?? zeroAmount,
          ),
          insuranceTaxes: addAmounts(
            final.total.tax,
            oneTime?.final.options.historyTakeover?.premium.tax ?? zeroAmount,
          ),
          terrorismTax: zeroAmount,
          assistanceVAT: zeroAmount,
          assistancePremiumWithoutTaxes: zeroAmount,
          totalPremium: addAmounts(
            isFirstOfYear ? final.total.firstMonthTotal : final.total.subsequentMonthsTotal,
            oneTime?.final.options.historyTakeover?.premium.total ?? zeroAmount,
          ),
          orusFee: addAmounts(
            final.total.commission,
            oneTime?.final.options.historyTakeover?.premium.commission ?? zeroAmount,
          ),
          installmentFee: getRcdaInstallmentFeePerMonth(rcdaQuote) ?? zeroAmount,
          partnerManagementFee: final.partnerFees?.managementFee?.premium.total ?? zeroAmount,
          orusManagementFee: final.orusFees?.managementFee?.premium.total ?? zeroAmount,
        },
      }
    },
    yearly: ({ input, isFirstEver }) => {
      const rcdaQuote = checkDefinedAndNotNull(input.rcdaQuote)
      const { withoutOrusDiscount, withoutPartnerFees, withoutOrusAndPartnerFees, final } = rcdaQuote.yearly
      const oneTime =
        isFirstEver && rcdaQuote.oneTime?.final.options.historyTakeover?.active === true ? rcdaQuote.oneTime : undefined

      return {
        withoutOrusDiscount: {
          premiumWithoutTaxes: addAmounts(
            withoutOrusDiscount.total.exTax,
            oneTime?.withoutOrusDiscount.options.historyTakeover?.premium.exTax ?? zeroAmount,
          ),
          insuranceTaxes: addAmounts(
            withoutOrusDiscount.total.tax,
            oneTime?.withoutOrusDiscount.options.historyTakeover?.premium.tax ?? zeroAmount,
          ),
          terrorismTax: zeroAmount,
          installmentFee: zeroAmount,
          assistanceVAT: zeroAmount,
          assistancePremiumWithoutTaxes: zeroAmount,
          totalPremium: addAmounts(
            withoutOrusDiscount.total.total,
            oneTime?.withoutOrusDiscount.options.historyTakeover?.premium.total ?? zeroAmount,
          ),
          orusFee: addAmounts(
            withoutOrusDiscount.total.commission,
            oneTime?.withoutOrusDiscount.options.historyTakeover?.premium.commission ?? zeroAmount,
          ),

          partnerManagementFee: zeroAmount,
          orusManagementFee: zeroAmount,
        },
        withoutPartnerFees: withoutPartnerFees
          ? {
              premiumWithoutTaxes: addAmounts(
                withoutPartnerFees.total.exTax,
                oneTime?.withoutPartnerFees?.options.historyTakeover?.premium.exTax ?? zeroAmount,
              ),
              insuranceTaxes: addAmounts(
                withoutPartnerFees.total.tax,
                oneTime?.withoutPartnerFees?.options.historyTakeover?.premium.tax ?? zeroAmount,
              ),
              terrorismTax: zeroAmount,
              installmentFee: zeroAmount,
              assistanceVAT: zeroAmount,
              assistancePremiumWithoutTaxes: zeroAmount,
              totalPremium: addAmounts(
                withoutPartnerFees.total.total,
                oneTime?.withoutPartnerFees?.options.historyTakeover?.premium.total ?? zeroAmount,
              ),
              orusFee: addAmounts(
                withoutPartnerFees.total.commission,
                oneTime?.withoutPartnerFees?.options.historyTakeover?.premium.commission ?? zeroAmount,
              ),

              partnerManagementFee: zeroAmount,
              orusManagementFee: zeroAmount,
            }
          : undefined,
        withoutOrusAndPartnerFees: withoutOrusAndPartnerFees
          ? {
              premiumWithoutTaxes: addAmounts(
                withoutOrusAndPartnerFees.total.exTax,
                oneTime?.withoutOrusAndPartnerFees?.options.historyTakeover?.premium.exTax ?? zeroAmount,
              ),
              insuranceTaxes: addAmounts(
                withoutOrusAndPartnerFees.total.tax,
                oneTime?.withoutOrusAndPartnerFees?.options.historyTakeover?.premium.tax ?? zeroAmount,
              ),
              terrorismTax: zeroAmount,
              installmentFee: zeroAmount,
              assistanceVAT: zeroAmount,
              assistancePremiumWithoutTaxes: zeroAmount,
              totalPremium: addAmounts(
                withoutOrusAndPartnerFees.total.total,
                oneTime?.withoutOrusAndPartnerFees?.options.historyTakeover?.premium.total ?? zeroAmount,
              ),
              orusFee: addAmounts(
                withoutOrusAndPartnerFees.total.commission,
                oneTime?.withoutOrusAndPartnerFees?.options.historyTakeover?.premium.commission ?? zeroAmount,
              ),

              partnerManagementFee: zeroAmount,
              orusManagementFee: zeroAmount,
            }
          : undefined,
        final: {
          premiumWithoutTaxes: addAmounts(
            final.total.exTax,
            oneTime?.final.options.historyTakeover?.premium.exTax ?? zeroAmount,
          ),
          insuranceTaxes: addAmounts(
            final.total.tax,
            oneTime?.final.options.historyTakeover?.premium.tax ?? zeroAmount,
          ),
          terrorismTax: zeroAmount,
          assistanceVAT: zeroAmount,
          assistancePremiumWithoutTaxes: zeroAmount,
          totalPremium: addAmounts(
            final.total.total,
            oneTime?.final.options.historyTakeover?.premium.total ?? zeroAmount,
          ),
          orusFee: addAmounts(
            final.total.commission,
            oneTime?.final.options.historyTakeover?.premium.commission ?? zeroAmount,
          ),
          installmentFee: zeroAmount,
          partnerManagementFee: final.partnerFees?.managementFee?.premium.total ?? zeroAmount,
          orusManagementFee: final.orusFees?.managementFee?.premium.total ?? zeroAmount,
        },
      }
    },
  },
  // ProductInformationId exist here for presentation purpose only, and the real information is in the quote
  'rcda-rc-pro': {
    monthly: () => freeProductAmounts,
    yearly: () => freeProductAmounts,
  },
  restaurant: {
    monthly: ({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }) =>
      mrpwAmountFactories.monthly({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }),
    yearly: ({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }) =>
      mrpwAmountFactories.yearly({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }),
  },
  'es-rcph-pro': esRcphAmountFactory,
  'es-rcph-general': esRcphAmountFactory,
  'mrp-small-office': {
    monthly: ({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }) =>
      rcphMrpSmallOfficeAmountFactories.monthly({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }),
    yearly: ({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }) =>
      rcphMrpSmallOfficeAmountFactories.yearly({ input, isInFirstYear, isFirstOfYear, isFirstEver, organizationType }),
  },
  rcpa: {
    monthly: ({ input, isFirstEver, isFirstOfYear, isInFirstYear, organizationType }) =>
      rcpaAmountFactories.monthly({ input, isFirstEver, isFirstOfYear, isInFirstYear, organizationType }),
    yearly: ({ input, isFirstEver, isFirstOfYear, isInFirstYear, organizationType }) =>
      rcpaAmountFactories.yearly({ input, isFirstEver, isFirstOfYear, isInFirstYear, organizationType }),
  },
}

const mrpwAmountFactories: Record<PaymentRecurrence, ItemAmountsFactory> = {
  monthly: ({ input, isFirstOfYear }) => {
    const mrpwQuote = assertSuccess(checkDefinedAndNotNull(input.mrpwQuote))

    return {
      withoutPartnerFees: {
        premiumWithoutTaxes: mrpwQuote.monthlyBasePremium,
        insuranceTaxes: mrpwQuote.monthlyTaxes,
        terrorismTax: isFirstOfYear ? mrpwQuote.terrorismTax : zeroAmount,
        assistanceVAT: mrpwQuote.monthlyAssistanceTaxes ?? zeroAmount,
        assistancePremiumWithoutTaxes: mrpwQuote.monthlyAssistanceBasePremium ?? zeroAmount,
        totalPremium: isFirstOfYear
          ? substractAmounts(
              mrpwQuote.firstMonthTotalPremium,
              mrpwQuote.monthlyPartnerManagementFeePremium ?? zeroAmount,
            )
          : substractAmounts(
              mrpwQuote.subsequentMonthsTotalPremium,
              mrpwQuote.monthlyPartnerManagementFeePremium ?? zeroAmount,
            ),
        orusFee: mrpwQuote.monthlyOrusFee,
        installmentFee: zeroAmount,

        partnerManagementFee: zeroAmount,
        orusManagementFee: zeroAmount,
      },
      withoutOrusAndPartnerFees: undefined,
      final: {
        premiumWithoutTaxes: mrpwQuote.monthlyBasePremium,
        insuranceTaxes: mrpwQuote.monthlyTaxes,
        terrorismTax: isFirstOfYear ? mrpwQuote.terrorismTax : zeroAmount,
        assistanceVAT: mrpwQuote.monthlyAssistanceTaxes ?? zeroAmount,
        assistancePremiumWithoutTaxes: mrpwQuote.monthlyAssistanceBasePremium ?? zeroAmount,
        totalPremium: isFirstOfYear ? mrpwQuote.firstMonthTotalPremium : mrpwQuote.subsequentMonthsTotalPremium,
        orusFee: mrpwQuote.monthlyOrusFee,
        installmentFee: zeroAmount,
        partnerManagementFee: mrpwQuote.monthlyPartnerManagementFeePremium ?? zeroAmount,
        orusManagementFee: zeroAmount,
      },
    }
  },
  yearly: ({ input }) => {
    const mrpwQuote = assertSuccess(checkDefinedAndNotNull(input.mrpwQuote))
    return {
      withoutPartnerFees: {
        premiumWithoutTaxes: mrpwQuote.yearlyBasePremium,
        insuranceTaxes: mrpwQuote.yearlyTaxes,
        terrorismTax: mrpwQuote.terrorismTax,
        assistanceVAT: mrpwQuote.yearlyAssistanceTaxes ?? zeroAmount,
        assistancePremiumWithoutTaxes: mrpwQuote.yearlyAssistanceBasePremium ?? zeroAmount,
        totalPremium: substractAmounts(
          mrpwQuote.yearlyTotalPremium,
          mrpwQuote.yearlyPartnerManagementFeePremium ?? zeroAmount,
        ),
        orusFee: mrpwQuote.yearlyOrusFee,
        installmentFee: zeroAmount,

        partnerManagementFee: zeroAmount,
        orusManagementFee: zeroAmount,
      },
      withoutOrusAndPartnerFees: undefined,
      final: {
        premiumWithoutTaxes: mrpwQuote.yearlyBasePremium,
        insuranceTaxes: mrpwQuote.yearlyTaxes,
        terrorismTax: mrpwQuote.terrorismTax,
        assistanceVAT: mrpwQuote.yearlyAssistanceTaxes ?? zeroAmount,
        assistancePremiumWithoutTaxes: mrpwQuote.yearlyAssistanceBasePremium ?? zeroAmount,
        totalPremium: mrpwQuote.yearlyTotalPremium,
        orusFee: mrpwQuote.yearlyOrusFee,
        installmentFee: zeroAmount,
        partnerManagementFee: mrpwQuote.yearlyPartnerManagementFeePremium ?? zeroAmount,
        orusManagementFee: zeroAmount,
      },
    }
  },
}

const rcphCyberAmountFactories: Record<PaymentRecurrence, ItemAmountsFactory> = {
  monthly: ({ input, isInFirstYear, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)
    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount = isInFirstYear
      ? rcphQuoteV2.monthly.withoutOrusDiscount
      : rcphQuoteV2.monthly.withoutAnyDiscount

    const totalCyberPremiumWithoutOrusDiscount = addMonthlyPremiums(
      allCyberGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.monthly.final
        : isInFirstYear
          ? rcphQuoteV2.monthly.final
          : rcphQuoteV2.monthly.withoutAnyDiscount

    const totalCyberPremium = addMonthlyPremiums(
      allCyberGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: totalCyberPremiumWithoutOrusDiscount.exTax,
        insuranceTaxes: totalCyberPremiumWithoutOrusDiscount.tax,
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: totalCyberPremiumWithoutOrusDiscount.total,
        orusFee: totalCyberPremiumWithoutOrusDiscount.commission,
        installmentFee: zeroAmount,

        partnerManagementFee: zeroAmount,
        orusManagementFee: zeroAmount,
      },
      withoutOrusAndPartnerFees: undefined,
      withoutPartnerFees: undefined,
      final: {
        premiumWithoutTaxes: totalCyberPremium.exTax,
        insuranceTaxes: totalCyberPremium.tax,
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: totalCyberPremium.total,
        orusFee: totalCyberPremium.commission,
        installmentFee: applicableFinal.orusFees?.installmentFee?.premium.total ?? zeroAmount,
        partnerManagementFee: applicableFinal.partnerFees?.managementFee?.premium.total ?? zeroAmount,
        orusManagementFee: applicableFinal.orusFees?.managementFee?.premium.total ?? zeroAmount,
      },
    }
  },
  yearly: ({ input, isInFirstYear, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)
    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount = isInFirstYear
      ? rcphQuoteV2.yearly.withoutOrusDiscount
      : rcphQuoteV2.yearly.withoutAnyDiscount

    const totalCyberPremiumWithoutOrusDiscount = addYearlyPremiums(
      allCyberGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.yearly.final
        : isInFirstYear
          ? rcphQuoteV2.yearly.final
          : rcphQuoteV2.yearly.withoutAnyDiscount

    const totalCyberPremium = addYearlyPremiums(
      allCyberGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: totalCyberPremiumWithoutOrusDiscount.exTax,
        insuranceTaxes: totalCyberPremiumWithoutOrusDiscount.tax,
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: totalCyberPremiumWithoutOrusDiscount.total,
        orusFee: totalCyberPremiumWithoutOrusDiscount.commission,
        installmentFee: zeroAmount,

        partnerManagementFee: zeroAmount,
        orusManagementFee: zeroAmount,
      },
      withoutOrusAndPartnerFees: undefined,
      withoutPartnerFees: undefined,
      final: {
        premiumWithoutTaxes: totalCyberPremium.exTax,
        insuranceTaxes: totalCyberPremium.tax,
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: totalCyberPremium.total,
        orusFee: totalCyberPremium.commission,
        installmentFee: applicableFinal.orusFees?.installmentFee?.premium.total ?? zeroAmount,
        partnerManagementFee: applicableFinal.partnerFees?.managementFee?.premium.total ?? zeroAmount,
        orusManagementFee: applicableFinal.orusFees?.managementFee?.premium.total ?? zeroAmount,
      },
    }
  },
}

const rcphTdmiAmountFactories: Record<PaymentRecurrence, ItemAmountsFactory> = {
  monthly: ({ input, isInFirstYear, isFirstOfYear, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)
    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount = isInFirstYear
      ? rcphQuoteV2.monthly.withoutOrusDiscount
      : rcphQuoteV2.monthly.withoutAnyDiscount

    const totalTdmiPremiumWithoutOrusDiscount = addMonthlyPremiums(
      allTdmiGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.monthly.final
        : isInFirstYear
          ? rcphQuoteV2.monthly.final
          : rcphQuoteV2.monthly.withoutAnyDiscount

    const totalTdmiPremium = addMonthlyPremiums(
      allTdmiGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: totalTdmiPremiumWithoutOrusDiscount.exTax,
        insuranceTaxes: totalTdmiPremiumWithoutOrusDiscount.tax,
        terrorismTax: isFirstOfYear ? (rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: isFirstOfYear
          ? addAmounts(totalTdmiPremiumWithoutOrusDiscount.total, rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount)
          : totalTdmiPremiumWithoutOrusDiscount.total,
        orusFee: totalTdmiPremiumWithoutOrusDiscount.commission,
        installmentFee: zeroAmount,

        partnerManagementFee: zeroAmount,
        orusManagementFee: zeroAmount,
      },
      withoutOrusAndPartnerFees: undefined,
      withoutPartnerFees: undefined,
      final: {
        premiumWithoutTaxes: totalTdmiPremium.exTax,
        insuranceTaxes: totalTdmiPremium.tax,
        terrorismTax: isFirstOfYear ? (rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: isFirstOfYear
          ? addAmounts(totalTdmiPremium.total, rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount)
          : totalTdmiPremium.total,
        orusFee: totalTdmiPremium.commission,
        installmentFee: applicableFinal.orusFees?.installmentFee?.premium.total ?? zeroAmount,
        partnerManagementFee: applicableFinal.partnerFees?.managementFee?.premium.total ?? zeroAmount,
        orusManagementFee: applicableFinal.orusFees?.managementFee?.premium.total ?? zeroAmount,
      },
    }
  },
  yearly: ({ input, isInFirstYear, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)
    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount = isInFirstYear
      ? rcphQuoteV2.yearly.withoutOrusDiscount
      : rcphQuoteV2.yearly.withoutAnyDiscount

    const totalTdmiPremiumWithoutOrusDiscount = addYearlyPremiums(
      allTdmiGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.yearly.final
        : isInFirstYear
          ? rcphQuoteV2.yearly.final
          : rcphQuoteV2.yearly.withoutAnyDiscount

    const totalTdmiPremium = addYearlyPremiums(
      allTdmiGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: totalTdmiPremiumWithoutOrusDiscount.exTax,
        insuranceTaxes: totalTdmiPremiumWithoutOrusDiscount.tax,
        terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: totalTdmiPremiumWithoutOrusDiscount.total,
        orusFee: totalTdmiPremiumWithoutOrusDiscount.commission,
        installmentFee: zeroAmount,

        partnerManagementFee: zeroAmount,
        orusManagementFee: zeroAmount,
      },
      withoutOrusAndPartnerFees: undefined,
      withoutPartnerFees: undefined,
      final: {
        premiumWithoutTaxes: totalTdmiPremium.exTax,
        insuranceTaxes: totalTdmiPremium.tax,
        terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: totalTdmiPremium.total,
        orusFee: totalTdmiPremium.commission,
        installmentFee: applicableFinal.orusFees?.installmentFee?.premium.total ?? zeroAmount,
        partnerManagementFee: applicableFinal.partnerFees?.managementFee?.premium.total ?? zeroAmount,
        orusManagementFee: applicableFinal.orusFees?.managementFee?.premium.total ?? zeroAmount,
      },
    }
  },
}

const rcphMrpSmallOfficeAmountFactories: Record<PaymentRecurrence, ItemAmountsFactory> = {
  monthly: ({ input, isInFirstYear, isFirstOfYear, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)
    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount = isInFirstYear
      ? rcphQuoteV2.monthly.withoutOrusDiscount
      : rcphQuoteV2.monthly.withoutAnyDiscount

    const totalMrpSmallOfficePremiumWithoutOrusDiscount = addMonthlyPremiums(
      allMrpSmallOfficeGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.monthly.final
        : isInFirstYear
          ? rcphQuoteV2.monthly.final
          : rcphQuoteV2.monthly.withoutAnyDiscount

    const totalMrpSmallOfficePremium = addMonthlyPremiums(
      allMrpSmallOfficeGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: totalMrpSmallOfficePremiumWithoutOrusDiscount.exTax,
        insuranceTaxes: totalMrpSmallOfficePremiumWithoutOrusDiscount.tax,
        terrorismTax: isFirstOfYear ? (rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: isFirstOfYear
          ? addAmounts(
              totalMrpSmallOfficePremiumWithoutOrusDiscount.total,
              rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
            )
          : totalMrpSmallOfficePremiumWithoutOrusDiscount.total,
        orusFee: totalMrpSmallOfficePremiumWithoutOrusDiscount.commission,
        installmentFee: zeroAmount,

        partnerManagementFee: zeroAmount,
        orusManagementFee: zeroAmount,
      },
      withoutOrusAndPartnerFees: undefined,
      withoutPartnerFees: undefined,
      final: {
        premiumWithoutTaxes: totalMrpSmallOfficePremium.exTax,
        insuranceTaxes: totalMrpSmallOfficePremium.tax,
        terrorismTax: isFirstOfYear ? (rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: isFirstOfYear
          ? addAmounts(totalMrpSmallOfficePremium.total, rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount)
          : totalMrpSmallOfficePremium.total,
        orusFee: totalMrpSmallOfficePremium.commission,
        installmentFee: applicableFinal.orusFees?.installmentFee?.premium.total ?? zeroAmount,
        partnerManagementFee: applicableFinal.partnerFees?.managementFee?.premium.total ?? zeroAmount,
        orusManagementFee: applicableFinal.orusFees?.managementFee?.premium.total ?? zeroAmount,
      },
    }
  },
  yearly: ({ input, isInFirstYear, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)
    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount = isInFirstYear
      ? rcphQuoteV2.yearly.withoutOrusDiscount
      : rcphQuoteV2.yearly.withoutAnyDiscount

    const totalMrpSmallOfficePremiumWithoutOrusDiscount = addYearlyPremiums(
      allMrpSmallOfficeGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.yearly.final
        : isInFirstYear
          ? rcphQuoteV2.yearly.final
          : rcphQuoteV2.yearly.withoutAnyDiscount

    const totalMrpSmallOfficePremium = addYearlyPremiums(
      allMrpSmallOfficeGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: totalMrpSmallOfficePremiumWithoutOrusDiscount.exTax,
        insuranceTaxes: totalMrpSmallOfficePremiumWithoutOrusDiscount.tax,
        terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: totalMrpSmallOfficePremiumWithoutOrusDiscount.total,
        orusFee: totalMrpSmallOfficePremiumWithoutOrusDiscount.commission,
        installmentFee: zeroAmount,

        partnerManagementFee: zeroAmount,
        orusManagementFee: zeroAmount,
      },
      withoutOrusAndPartnerFees: undefined,
      withoutPartnerFees: undefined,
      final: {
        premiumWithoutTaxes: totalMrpSmallOfficePremium.exTax,
        insuranceTaxes: totalMrpSmallOfficePremium.tax,
        terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: totalMrpSmallOfficePremium.total,
        orusFee: totalMrpSmallOfficePremium.commission,
        installmentFee: applicableFinal.orusFees?.installmentFee?.premium.total ?? zeroAmount,
        partnerManagementFee: applicableFinal.partnerFees?.managementFee?.premium.total ?? zeroAmount,
        orusManagementFee: applicableFinal.orusFees?.managementFee?.premium.total ?? zeroAmount,
      },
    }
  },
}

const rcphWithoutCyberAndTdmiAmountFactories: Record<PaymentRecurrence, ItemAmountsFactory> = {
  monthly: ({ input, isInFirstYear, isFirstOfYear, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)
    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount = isInFirstYear
      ? rcphQuoteV2.monthly.withoutOrusDiscount
      : rcphQuoteV2.monthly.withoutAnyDiscount

    const totalRcphPremiumWithoutOrusDiscount = applicableFinalWithoutOrusDiscount.total

    const totalTdmiPremiumWithoutOrusDiscount = addMonthlyPremiums(
      allTdmiGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const totalCyberPremiumWithoutOrusDiscount = addMonthlyPremiums(
      allCyberGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.monthly.final
        : isInFirstYear
          ? rcphQuoteV2.monthly.final
          : rcphQuoteV2.monthly.withoutAnyDiscount

    const totalRcphPremium = applicableFinal.total

    const totalTdmiPremium = addMonthlyPremiums(
      allTdmiGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    const totalCyberPremium = addMonthlyPremiums(
      allCyberGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    const totalMonthlyRcphPremiumWithoutOrusDiscount = isFirstOfYear
      ? totalRcphPremiumWithoutOrusDiscount.firstMonthTotal
      : totalRcphPremiumWithoutOrusDiscount.subsequentMonthsTotal

    const totalMonthlyTdmiPremiumWithoutOrusDiscount = isFirstOfYear
      ? addAmounts(totalTdmiPremiumWithoutOrusDiscount.total, rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount)
      : totalTdmiPremiumWithoutOrusDiscount.total

    const totalMonthlyCyberPremiumWithoutOrusDiscount = totalCyberPremiumWithoutOrusDiscount.total

    const totalMonthlyRcphPremium = isFirstOfYear
      ? totalRcphPremium.firstMonthTotal
      : totalRcphPremium.subsequentMonthsTotal

    const totalMonthlyTdmiPremium = isFirstOfYear
      ? addAmounts(totalTdmiPremium.total, rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount)
      : totalTdmiPremium.total

    const totalMonthlyCyberPremium = totalCyberPremium.total

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: substractAmounts(
          totalRcphPremiumWithoutOrusDiscount.exTax,
          addAmounts(totalTdmiPremiumWithoutOrusDiscount.exTax, totalCyberPremiumWithoutOrusDiscount.exTax),
        ),
        insuranceTaxes: substractAmounts(
          totalRcphPremiumWithoutOrusDiscount.tax,
          addAmounts(totalTdmiPremiumWithoutOrusDiscount.tax, totalCyberPremiumWithoutOrusDiscount.tax),
        ),
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: substractAmounts(
          totalMonthlyRcphPremiumWithoutOrusDiscount,
          addAmounts(totalMonthlyTdmiPremiumWithoutOrusDiscount, totalMonthlyCyberPremiumWithoutOrusDiscount),
        ),
        orusFee: substractAmounts(
          totalRcphPremiumWithoutOrusDiscount.commission,
          addAmounts(totalTdmiPremiumWithoutOrusDiscount.commission, totalCyberPremiumWithoutOrusDiscount.commission),
        ),
        installmentFee: zeroAmount,

        partnerManagementFee: zeroAmount,
        orusManagementFee: zeroAmount,
      },
      withoutOrusAndPartnerFees: undefined,
      withoutPartnerFees: undefined,
      final: {
        premiumWithoutTaxes: substractAmounts(
          totalRcphPremium.exTax,
          addAmounts(totalTdmiPremium.exTax, totalCyberPremium.exTax),
        ),
        insuranceTaxes: substractAmounts(totalRcphPremium.tax, addAmounts(totalTdmiPremium.tax, totalCyberPremium.tax)),
        terrorismTax: zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: substractAmounts(
          totalMonthlyRcphPremium,
          addAmounts(totalMonthlyTdmiPremium, totalMonthlyCyberPremium),
        ),
        orusFee: substractAmounts(
          totalRcphPremium.commission,
          addAmounts(totalTdmiPremium.commission, totalCyberPremium.commission),
        ),
        installmentFee: applicableFinal.orusFees?.installmentFee?.premium.total ?? zeroAmount,
        partnerManagementFee: applicableFinal.partnerFees?.managementFee?.premium.total ?? zeroAmount,
        orusManagementFee: applicableFinal.orusFees?.managementFee?.premium.total ?? zeroAmount,
      },
    }
  },
  yearly: ({ input, isInFirstYear, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)
    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount = isInFirstYear
      ? rcphQuoteV2.yearly.withoutOrusDiscount
      : rcphQuoteV2.yearly.withoutAnyDiscount

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.yearly.final
        : isInFirstYear
          ? rcphQuoteV2.yearly.final
          : rcphQuoteV2.yearly.withoutAnyDiscount

    const totalRcphPremiumWithoutOrusDiscount = applicableFinalWithoutOrusDiscount.total

    const totalTdmiPremiumWithoutOrusDiscount = addYearlyPremiums(
      allTdmiGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const totalCyberPremiumWithoutOrusDiscount = addYearlyPremiums(
      allCyberGuarantees.map((guarantee) => {
        if (!applicableFinalWithoutOrusDiscount.options[guarantee]?.active) return
        return applicableFinalWithoutOrusDiscount.options[guarantee]?.premium
      }),
    )

    const totalRcphPremium = applicableFinal.total

    const totalTdmiPremium = addYearlyPremiums(
      allTdmiGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    const totalCyberPremium = addYearlyPremiums(
      allCyberGuarantees.map((guarantee) => {
        if (!applicableFinal.options[guarantee]?.active) return
        return applicableFinal.options[guarantee]?.premium
      }),
    )

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: substractAmounts(
          totalRcphPremiumWithoutOrusDiscount.exTax,
          addAmounts(totalTdmiPremiumWithoutOrusDiscount.exTax, totalCyberPremiumWithoutOrusDiscount.exTax),
        ),
        insuranceTaxes: substractAmounts(
          totalRcphPremiumWithoutOrusDiscount.tax,
          addAmounts(totalTdmiPremiumWithoutOrusDiscount.tax, totalCyberPremiumWithoutOrusDiscount.tax),
        ),
        terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: substractAmounts(
          totalRcphPremiumWithoutOrusDiscount.total,
          addAmounts(totalTdmiPremiumWithoutOrusDiscount.total, totalCyberPremiumWithoutOrusDiscount.total),
        ),
        orusFee: substractAmounts(
          totalRcphPremiumWithoutOrusDiscount.commission,
          addAmounts(totalRcphPremiumWithoutOrusDiscount.commission, totalRcphPremiumWithoutOrusDiscount.commission),
        ),
        installmentFee: zeroAmount,

        partnerManagementFee: zeroAmount,
        orusManagementFee: zeroAmount,
      },
      withoutOrusAndPartnerFees: undefined,
      withoutPartnerFees: undefined,
      final: {
        premiumWithoutTaxes: substractAmounts(
          totalRcphPremium.exTax,
          addAmounts(totalTdmiPremium.exTax, totalCyberPremium.exTax),
        ),
        insuranceTaxes: substractAmounts(totalRcphPremium.tax, addAmounts(totalTdmiPremium.tax, totalCyberPremium.tax)),
        terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: substractAmounts(
          totalRcphPremium.total,
          addAmounts(totalTdmiPremium.total, totalCyberPremium.total),
        ),
        orusFee: substractAmounts(
          totalRcphPremium.commission,
          addAmounts(totalRcphPremium.commission, totalRcphPremium.commission),
        ),
        installmentFee: applicableFinal.orusFees?.installmentFee?.premium.total ?? zeroAmount,
        partnerManagementFee: applicableFinal.partnerFees?.managementFee?.premium.total ?? zeroAmount,
        orusManagementFee: applicableFinal.orusFees?.managementFee?.premium.total ?? zeroAmount,
      },
    }
  },
}

const rcphAmountFactories: Record<PaymentRecurrence, ItemAmountsFactory> = {
  monthly: ({ input, isInFirstYear, isFirstOfYear, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)

    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount = isInFirstYear
      ? rcphQuoteV2.monthly.withoutOrusDiscount
      : rcphQuoteV2.monthly.withoutAnyDiscount

    const withoutPartnerFees = rcphQuoteV2.monthly.withoutPartnerFees
    const withoutOrusAndPartnerFees = rcphQuoteV2.monthly.withoutOrusAndPartnerFees

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.monthly.final
        : isInFirstYear
          ? rcphQuoteV2.monthly.final
          : rcphQuoteV2.monthly.withoutAnyDiscount

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: applicableFinalWithoutOrusDiscount.total.exTax,
        insuranceTaxes: applicableFinalWithoutOrusDiscount.total.tax,
        terrorismTax: isFirstOfYear ? (rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: isFirstOfYear
          ? applicableFinalWithoutOrusDiscount.total.firstMonthTotal
          : applicableFinalWithoutOrusDiscount.total.subsequentMonthsTotal,
        orusFee: applicableFinalWithoutOrusDiscount.total.commission,
        installmentFee: zeroAmount,

        partnerManagementFee: zeroAmount,
        orusManagementFee: zeroAmount,
      },
      withoutPartnerFees: withoutPartnerFees
        ? {
            premiumWithoutTaxes: withoutPartnerFees.total.exTax,
            insuranceTaxes: withoutPartnerFees.total.tax,
            terrorismTax: isFirstOfYear ? (rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
            assistanceVAT: zeroAmount,
            assistancePremiumWithoutTaxes: zeroAmount,
            totalPremium: isFirstOfYear
              ? withoutPartnerFees.total.firstMonthTotal
              : withoutPartnerFees.total.subsequentMonthsTotal,
            orusFee: withoutPartnerFees.total.commission,
            installmentFee: zeroAmount,

            partnerManagementFee: zeroAmount,
            orusManagementFee: zeroAmount,
          }
        : undefined,
      withoutOrusAndPartnerFees: withoutOrusAndPartnerFees
        ? {
            premiumWithoutTaxes: withoutOrusAndPartnerFees.total.exTax,
            insuranceTaxes: withoutOrusAndPartnerFees.total.tax,
            terrorismTax: isFirstOfYear ? (rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
            assistanceVAT: zeroAmount,
            assistancePremiumWithoutTaxes: zeroAmount,
            totalPremium: isFirstOfYear
              ? withoutOrusAndPartnerFees.total.firstMonthTotal
              : withoutOrusAndPartnerFees.total.subsequentMonthsTotal,
            orusFee: withoutOrusAndPartnerFees.total.commission,
            installmentFee: zeroAmount,

            partnerManagementFee: zeroAmount,
            orusManagementFee: zeroAmount,
          }
        : undefined,
      final: {
        premiumWithoutTaxes: applicableFinal.total.exTax,
        insuranceTaxes: applicableFinal.total.tax,
        terrorismTax: isFirstOfYear ? (rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount) : zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: isFirstOfYear
          ? applicableFinal.total.firstMonthTotal
          : applicableFinal.total.subsequentMonthsTotal,
        orusFee: applicableFinal.total.commission,
        installmentFee: applicableFinal.orusFees?.installmentFee?.premium.total ?? zeroAmount,
        partnerManagementFee: applicableFinal.partnerFees?.managementFee?.premium.total ?? zeroAmount,
        orusManagementFee: applicableFinal.orusFees?.managementFee?.premium.total ?? zeroAmount,
      },
    }
  },
  yearly: ({ input, isInFirstYear, organizationType }) => {
    const rcphQuoteV2 = checkDefinedAndNotNull(input.rcphQuoteV2)
    // discounts applicable on first year
    const applicableFinalWithoutOrusDiscount = isInFirstYear
      ? rcphQuoteV2.yearly.withoutOrusDiscount
      : rcphQuoteV2.yearly.withoutAnyDiscount

    const withoutPartnerFees = rcphQuoteV2.yearly.withoutPartnerFees
    const withoutOrusAndPartnerFees = rcphQuoteV2.yearly.withoutOrusAndPartnerFees

    const applicableFinal =
      organizationType === 'broker'
        ? rcphQuoteV2.yearly.final
        : isInFirstYear
          ? rcphQuoteV2.yearly.final
          : rcphQuoteV2.yearly.withoutAnyDiscount

    return {
      withoutOrusDiscount: {
        premiumWithoutTaxes: applicableFinalWithoutOrusDiscount.total.exTax,
        insuranceTaxes: applicableFinalWithoutOrusDiscount.total.tax,
        terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: applicableFinalWithoutOrusDiscount.total.total,
        orusFee: applicableFinalWithoutOrusDiscount.total.commission,
        installmentFee: zeroAmount,

        partnerManagementFee: zeroAmount,
        orusManagementFee: zeroAmount,
      },
      withoutPartnerFees: withoutPartnerFees
        ? {
            premiumWithoutTaxes: withoutPartnerFees.total.exTax,
            insuranceTaxes: withoutPartnerFees.total.tax,
            terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
            assistanceVAT: zeroAmount,
            assistancePremiumWithoutTaxes: zeroAmount,
            totalPremium: withoutPartnerFees.total.total,
            orusFee: withoutPartnerFees.total.commission,
            installmentFee: zeroAmount,

            partnerManagementFee: zeroAmount,
            orusManagementFee: zeroAmount,
          }
        : undefined,
      withoutOrusAndPartnerFees: withoutOrusAndPartnerFees
        ? {
            premiumWithoutTaxes: withoutOrusAndPartnerFees.total.exTax,
            insuranceTaxes: withoutOrusAndPartnerFees.total.tax,
            terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
            assistanceVAT: zeroAmount,
            assistancePremiumWithoutTaxes: zeroAmount,
            totalPremium: withoutOrusAndPartnerFees.total.total,
            orusFee: withoutOrusAndPartnerFees.total.commission,
            installmentFee: zeroAmount,

            partnerManagementFee: zeroAmount,
            orusManagementFee: zeroAmount,
          }
        : undefined,
      final: {
        premiumWithoutTaxes: applicableFinal.total.exTax,
        insuranceTaxes: applicableFinal.total.tax,
        terrorismTax: rcphQuoteV2.yearlyTaxes.terrorism ?? zeroAmount,
        assistanceVAT: zeroAmount,
        assistancePremiumWithoutTaxes: zeroAmount,
        totalPremium: applicableFinal.total.total,
        orusFee: applicableFinal.total.commission,
        installmentFee: applicableFinal.orusFees?.installmentFee?.premium.total ?? zeroAmount,
        partnerManagementFee: applicableFinal.partnerFees?.managementFee?.premium.total ?? zeroAmount,
        orusManagementFee: applicableFinal.orusFees?.managementFee?.premium.total ?? zeroAmount,
      },
    }
  },
}

const rcpaAmountFactories: Record<PaymentRecurrence, ItemAmountsFactory> = {
  monthly: ({ input, isFirstEver, isFirstOfYear }) => {
    const rcpaQuote = assertDefinedAndNotNull(
      input.rcpaQuote,
      'RCPA quote is not defined in invoice item amount factories',
      {
        context: { input },
      },
    )

    const quoteCalculator = new QuoteCalculator(rcpaQuote)

    return {
      withoutPartnerFees: {
        assistancePremiumWithoutTaxes: zeroAmount,
        assistanceVAT: zeroAmount,
        insuranceTaxes: zeroAmount,
        terrorismTax: zeroAmount,
        totalPremium: zeroAmount,
        installmentFee: zeroAmount,
        partnerManagementFee: zeroAmount,
        orusManagementFee: zeroAmount,
        orusFee: zeroAmount,
        premiumWithoutTaxes: zeroAmount,
      },
      withoutOrusAndPartnerFees: {
        assistancePremiumWithoutTaxes: zeroAmount,
        assistanceVAT: zeroAmount,
        insuranceTaxes: zeroAmount,
        terrorismTax: zeroAmount,
        totalPremium: zeroAmount,
        installmentFee: zeroAmount,
        partnerManagementFee: zeroAmount,
        orusManagementFee: zeroAmount,
        orusFee: zeroAmount,
        premiumWithoutTaxes: zeroAmount,
      },
      final: {
        assistancePremiumWithoutTaxes: zeroAmount,
        assistanceVAT: zeroAmount,
        terrorismTax: zeroAmount,
        orusFee: zeroAmount,
        partnerManagementFee: quoteCalculator.getMonthlyOrusInstallmentFeeAmountForPaymentRecurrence({
          paymentRecurrence: 'monthly',
          isAnnuityFirstMonth: isFirstOfYear,
          isContractFirstMonth: isFirstEver,
        }),
        installmentFee: quoteCalculator.getMonthlyPartnerManagementFeeAmountForPaymentRecurrence({
          paymentRecurrence: 'monthly',
          isAnnuityFirstMonth: isFirstOfYear,
          isContractFirstMonth: isFirstEver,
        }),
        orusManagementFee: quoteCalculator.getMonthlyOrusManagementFeeAmountForPaymentRecurrence({
          paymentRecurrence: 'monthly',
          isAnnuityFirstMonth: isFirstOfYear,
          isContractFirstMonth: isFirstEver,
        }),
        premiumWithoutTaxes: quoteCalculator.getMonthlyTotalExTaxAmountForPaymentRecurrence({
          paymentRecurrence: 'monthly',
          isAnnuityFirstMonth: isFirstOfYear,
          isContractFirstMonth: isFirstEver,
        }),
        insuranceTaxes: quoteCalculator.getMonthlyTotalTaxesAmountForPaymentRecurrence({
          paymentRecurrence: 'monthly',
          isAnnuityFirstMonth: isFirstOfYear,
          isContractFirstMonth: isFirstEver,
        }),
        totalPremium: quoteCalculator.getMonthlyTotalAmountForPaymentRecurrence({
          paymentRecurrence: 'monthly',
          isAnnuityFirstMonth: isFirstOfYear,
          isContractFirstMonth: isFirstEver,
        }),
      },
    }
  },
  yearly: ({ input, isFirstEver, isFirstOfYear }) => {
    const rcpaQuote = assertDefinedAndNotNull(
      input.rcpaQuote,
      'RCPA quote is not defined in invoice item amount factories',
      {
        context: { input },
      },
    )

    const quoteCalculator = new QuoteCalculator(rcpaQuote)

    return {
      withoutPartnerFees: {
        assistancePremiumWithoutTaxes: zeroAmount,
        assistanceVAT: zeroAmount,
        insuranceTaxes: zeroAmount,
        terrorismTax: zeroAmount,
        totalPremium: zeroAmount,
        installmentFee: zeroAmount,
        partnerManagementFee: zeroAmount,
        orusManagementFee: zeroAmount,
        orusFee: zeroAmount,
        premiumWithoutTaxes: zeroAmount,
      },
      withoutOrusAndPartnerFees: {
        assistancePremiumWithoutTaxes: zeroAmount,
        assistanceVAT: zeroAmount,
        insuranceTaxes: zeroAmount,
        terrorismTax: zeroAmount,
        totalPremium: zeroAmount,
        installmentFee: zeroAmount,
        partnerManagementFee: zeroAmount,
        orusManagementFee: zeroAmount,
        orusFee: zeroAmount,
        premiumWithoutTaxes: zeroAmount,
      },
      final: {
        assistancePremiumWithoutTaxes: zeroAmount,
        assistanceVAT: zeroAmount,
        terrorismTax: zeroAmount,
        orusFee: zeroAmount,
        installmentFee: quoteCalculator.getMonthlyOrusInstallmentFeeAmountForPaymentRecurrence({
          paymentRecurrence: 'yearly',
          isAnnuityFirstMonth: isFirstOfYear,
          isContractFirstMonth: isFirstEver,
        }),
        partnerManagementFee: quoteCalculator.getMonthlyPartnerManagementFeeAmountForPaymentRecurrence({
          paymentRecurrence: 'yearly',
          isAnnuityFirstMonth: isFirstOfYear,
          isContractFirstMonth: isFirstEver,
        }),
        orusManagementFee: quoteCalculator.getMonthlyOrusManagementFeeAmountForPaymentRecurrence({
          paymentRecurrence: 'yearly',
          isAnnuityFirstMonth: isFirstOfYear,
          isContractFirstMonth: isFirstEver,
        }),
        premiumWithoutTaxes: quoteCalculator.getYearlyTotalExTaxAmountForPaymentRecurrence({
          paymentRecurrence: 'yearly',
          isAnnuityFirstMonth: isFirstOfYear,
          isContractFirstMonth: isFirstEver,
        }),
        insuranceTaxes: quoteCalculator.getYearlyTotalTaxesAmountForPaymentRecurrence({
          paymentRecurrence: 'yearly',
          isAnnuityFirstMonth: isFirstOfYear,
          isContractFirstMonth: isFirstEver,
        }),
        totalPremium: quoteCalculator.getYearlyTotalAmountForPaymentRecurrence({
          paymentRecurrence: 'yearly',
          isAnnuityFirstMonth: isFirstOfYear,
          isContractFirstMonth: isFirstEver,
        }),
      },
    }
  },
}

export const productAmountFactories: Record<Product, Record<PaymentRecurrence, ItemAmountsFactory>> = {
  mrph: productInformationIdAmountFactories.mrph,
  mrpw: productInformationIdAmountFactories.mrpw,
  rcph: rcphAmountFactories,
  muta: productInformationIdAmountFactories.muta,
  rcda: productInformationIdAmountFactories.rcda,
  'es-rcph': esRcphAmountFactory,
  rcpa: productInformationIdAmountFactories.rcpa,
}

type GetAmountLabelParams = {
  productType:
    | { type: 'product'; product: Product }
    | { type: 'productInformationId'; productInformationId: ProductInformationId }
  state: CoverageInvoicingItemInputState
  organizationType: OrganizationType
}

export type GetAmountLabel = { price: string; recurrence: string | undefined } | undefined

export function getAmountLabel(
  { productType, state, organizationType }: GetAmountLabelParams,
  language: Language,
): GetAmountLabel {
  const amount = getEffectiveTotalAmount({ productType, state, organizationType })
  if (!amount) return

  const price = localizeAmount(
    {
      amount,
      type: checkDefinedAndNotNull(state.paymentRecurrence),
    },
    language,
  )

  if (typeof amount !== 'string' && !areAmountsEqual(amount, zeroAmount)) {
    const recurrence = localizeRecurrence(checkDefinedAndNotNull(state.paymentRecurrence), language)

    return { price, recurrence }
  }

  return { price, recurrence: undefined }
}

function getEffectiveTotalAmount({ productType, state, organizationType }: GetAmountLabelParams): Amount | undefined {
  const isRcda =
    (productType.type === 'product' && productType.product === 'rcda') ||
    (productType.type === 'productInformationId' && productType.productInformationId === 'rcda')

  if (productType.type === 'product') {
    if (productType.product === 'rcph' && !state.rcphQuoteV2) return
    if (productType.product === 'mrpw' && !state.mrpwQuote) return
    if (productType.product === 'mrph' && !state.mrphQuoteV2) return
    if (productType.product === 'muta' && !state.mutaQuote) return
    if (productType.product === 'rcda' && !state.rcdaQuote) return
    if (productType.product === 'rcpa' && !state.rcpaQuote) return

    return state.paymentRecurrence === 'monthly'
      ? productAmountFactories[productType.product].monthly({
          input: state,
          //We display the 2nd month, without one time taxes as terrorism tax.
          isFirstOfYear: false,
          isInFirstYear: true,
          isFirstEver: false,
          organizationType,
        }).final.totalPremium
      : productAmountFactories[productType.product].yearly({
          input: state,
          isFirstOfYear: true,
          //We display the 2nd year for rcda, without one time option as history takeover and first year otherwise.
          isInFirstYear: isRcda ? false : true,
          isFirstEver: !isRcda,
          organizationType,
        }).final.totalPremium
  }

  if (
    (productType.productInformationId === 'rc-pro' ||
      productType.productInformationId === 'rcph-cyber' ||
      productType.productInformationId === 'rcph-pj' ||
      productType.productInformationId === 'rcph-tdmi') &&
    !state.rcphQuoteV2
  )
    return

  if (
    (productType.productInformationId === 'mrpw' || productType.productInformationId === 'restaurant') &&
    !state.mrpwQuote
  )
    return
  if (productType.productInformationId === 'mrph' && !state.mrphQuoteV2) return
  if (productType.productInformationId === 'muta' && !state.mutaQuote) return
  if (
    (productType.productInformationId === 'rcda' || productType.productInformationId === 'rcda-rc-pro') &&
    !state.rcdaQuote
  )
    return

  return state.paymentRecurrence === 'monthly'
    ? productInformationIdAmountFactories[productType.productInformationId].monthly({
        input: state,
        //We display the 2nd month, without one time taxes as terrorism tax.
        isFirstOfYear: false,
        isInFirstYear: true,
        isFirstEver: false,
        organizationType,
      }).final.totalPremium
    : productInformationIdAmountFactories[productType.productInformationId].yearly({
        input: state,
        isFirstOfYear: true,
        //We display the 2nd year for rcda, without one time option as history takeover and first year otherwise.
        isInFirstYear: isRcda ? false : true,
        isFirstEver: !isRcda,
        organizationType,
      }).final.totalPremium
}

export function adaptInvoiceIndexToNewApi(
  invoiceIndex: number,
  paymentRecurrence: PaymentRecurrence,
): {
  isFirstOfYear: boolean
  isInFirstYear: boolean
  isFirstEver: boolean
} {
  const isFirstEver = invoiceIndex === 0
  if (paymentRecurrence === 'monthly') {
    const isFirstOfYear = invoiceIndex % 12 === 0
    const isInFirstYear = invoiceIndex < 12

    return { isFirstOfYear, isInFirstYear, isFirstEver }
  }

  const isFirstOfYear = true
  const isInFirstYear = invoiceIndex === 0

  return {
    isFirstOfYear,
    isInFirstYear,
    isFirstEver,
  }
}
