import { type Amount, applyRateToAmount, divideByNumber, multiplyByRate, zeroAmount } from '@orus.eu/amount'
import type { PaymentRecurrence } from '../../payment-recurrence'
import type { InsuranceOutput } from '../insurance-output'

export const monthsInAYear = 12

type InsuranceOutputAmountOptions =
  | {
      type: 'partnerManagementFee'
    }
  | {
      type: 'orusManagementFee'
    }
  | {
      type: 'orusInstallmentFee'
    }

export function getInsuranceOutputAmountForPaymentRecurrence(
  insuranceOutput: InsuranceOutput,
  paymentRecurrence: PaymentRecurrence,
  options?: InsuranceOutputAmountOptions,
): Amount {
  switch (paymentRecurrence) {
    case 'monthly': {
      return divideByNumber(getInsuranceOutputAmount(insuranceOutput, options), monthsInAYear)
    }
    case 'yearly': {
      return getInsuranceOutputAmount(insuranceOutput, options)
    }
  }
}

export function getInsuranceOutputAmount(
  insuranceOutput: InsuranceOutput,
  options?: InsuranceOutputAmountOptions,
): Amount {
  let amount = insuranceOutput.yearlyExTax

  //If there are no fees and rates, we apply the tax rate to the ex tax amount and early exit
  if (!insuranceOutput.feesAndRates) return applyRateToAmount(amount, insuranceOutput.taxRate)

  const applicableFeesAndRates = insuranceOutput.feesAndRates

  //We apply the orus overcharge rate to the ex tax amount
  if (applicableFeesAndRates.orus?.overchargeRate) {
    amount = applyRateToAmount(amount, applicableFeesAndRates.orus.overchargeRate)
  }

  //We apply the discount rate to the amount
  if (applicableFeesAndRates.orus?.discountRate) {
    amount = applyRateToAmount(amount, applicableFeesAndRates.orus.discountRate)
  }

  //We apply the tax rate to the amount
  if (insuranceOutput.taxRate) {
    amount = applyRateToAmount(amount, insuranceOutput.taxRate)
  }

  //We apply the fees to the amount
  //#region We apply the orus fees to the amount
  if (options?.type === 'orusInstallmentFee') {
    if (applicableFeesAndRates.orus?.installmentFeeRate) {
      return multiplyByRate(amount, applicableFeesAndRates.orus.installmentFeeRate)
    } else {
      return zeroAmount
    }
  }

  if (applicableFeesAndRates.orus?.installmentFeeRate) {
    amount = applyRateToAmount(amount, applicableFeesAndRates.orus.installmentFeeRate)
  }

  if (options?.type === 'orusManagementFee') {
    if (applicableFeesAndRates.orus?.managementFeeRate) {
      return multiplyByRate(amount, applicableFeesAndRates.orus.managementFeeRate)
    } else {
      return zeroAmount
    }
  }

  if (applicableFeesAndRates.orus?.managementFeeRate) {
    amount = applyRateToAmount(amount, applicableFeesAndRates.orus.managementFeeRate)
  }

  //#endregion

  //#region We apply the partner fees to the amount
  if (options?.type === 'partnerManagementFee') {
    if (applicableFeesAndRates.partner?.managementFeeRate) {
      return multiplyByRate(amount, applicableFeesAndRates.partner.managementFeeRate)
    } else {
      return zeroAmount
    }
  }

  if (applicableFeesAndRates.partner) {
    if (applicableFeesAndRates.partner.managementFeeRate) {
      amount = applyRateToAmount(amount, applicableFeesAndRates.partner.managementFeeRate)
    }
  }
  //#endregion
  return amount
}
